import React from 'react'

import { AppApolloProvider } from './src/apollo/AppApolloProvider'

import { GatsbyBrowser } from 'gatsby'

import { CustomerProvider } from './src/context/customerContext'
import { LocationProvider } from './src/context/locationContext'
import { PaypalProvider } from './src/context/paypalProvider'
import { WoocommerceProvider } from './src/context/woocommerceProvider'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
	return (
		<PaypalProvider>
			<WoocommerceProvider>
				<AppApolloProvider>
					<CustomerProvider>
						<LocationProvider>{element}</LocationProvider>
					</CustomerProvider>
				</AppApolloProvider>
			</WoocommerceProvider>
		</PaypalProvider>
	)
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }) => {
	const { pathname } = routerProps.location

	// If pathname starts with "/insights/", retain scroll position
	if (pathname.startsWith('/insights/') || pathname.startsWith('/news/')) {
		return false
	}

	// Stop scrolling on /products/ pages
	return !pathname.startsWith('/products/')
}
