import { gql } from '@apollo/client'

import { WoocommerceUser } from '@contracts/context/woocommerceUser'

import { client } from '@localApollo/client'

export const useGetCustomer = async (id: number) => {
	try {
		return await client.query({
			query: GET_CUSTOMER,
			variables: {
				customerId: id,
			},
		})
	} catch (error) {
		return false
	}
}

export const GET_CUSTOMER = gql`
	query getCustomer($id: Int!) {
		customer(customerId: $id) {
			id
			databaseId
			firstName
			lastName
			displayName
			email
			orderCount
			billing {
				address1
				address2
				city
				company
				email
				firstName
				lastName
				phone
				postcode
				state
				country
			}
			shipping {
				address1
				address2
				city
				company
				email
				firstName
				lastName
				phone
				postcode
				state
				country
			}
			metaData(key: "newsletter") {
				id
				key
				value
			}
			orders {
				nodes {
					cartHash
					cartTax
					databaseId
					dateCompleted
					datePaid
					discountTotal
					lineItems {
						nodes {
							total
							subtotal
							quantity
							product {
								node {
									id
									databaseId
									name
									description
									type
									onSale
									slug
									sku
									averageRating
									reviewCount
									productCategories {
										nodes {
											name
										}
									}
									image {
										id
										sourceUrl
										srcSet
										altText
										title
									}
									galleryImages {
										nodes {
											id
											sourceUrl
											srcSet
											altText
											title
										}
									}
									... on SimpleProduct {
										crossSell {
											nodes {
												id
												databaseId
												name
												type
												onSale
												slug
												image {
													id
													sourceUrl
													srcSet
													altText
													title
												}
												... on SimpleProduct {
													id
													price
													regularPrice
													salePrice
												}
											}
										}
										price
										regularPrice
										salePrice
									}
									... on VariableProduct {
										variations {
											nodes {
												attributes {
													nodes {
														name
														label
														value
													}
												}
											}
										}
									}
								}
							}
							variation {
								node {
									attributes {
										nodes {
											name
											label
											value
										}
									}
								}
							}
						}
					}
					needsPayment
					needsProcessing
					orderNumber
					paymentMethodTitle
					status
					subtotal
					total
					totalTax
					shippingTotal
					shippingTax
					billing {
						address1
						address2
						city
						company
						email
						firstName
						lastName
						phone
						postcode
						state
					}
					shipping {
						address1
						address2
						city
						company
						email
						firstName
						lastName
						phone
						postcode
						state
					}
				}
			}
		}
	}
`

export interface GetCustomerResponseType {
	customer: WoocommerceUser
}
