exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-order-success-tsx": () => import("./../../../src/pages/order/success.tsx" /* webpackChunkName: "component---src-pages-order-success-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-faqs-tsx": () => import("./../../../src/templates/faqs.tsx" /* webpackChunkName: "component---src-templates-faqs-tsx" */),
  "component---src-templates-finance-tsx": () => import("./../../../src/templates/finance.tsx" /* webpackChunkName: "component---src-templates-finance-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-info-page-tsx": () => import("./../../../src/templates/InfoPage.tsx" /* webpackChunkName: "component---src-templates-info-page-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/newsArchive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/newsSingle.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-plain-tsx": () => import("./../../../src/templates/plain.tsx" /* webpackChunkName: "component---src-templates-plain-tsx" */),
  "component---src-templates-product-archive-tsx": () => import("./../../../src/templates/ProductArchive.tsx" /* webpackChunkName: "component---src-templates-product-archive-tsx" */),
  "component---src-templates-product-product-variable-tsx": () => import("./../../../src/templates/product/ProductVariable.tsx" /* webpackChunkName: "component---src-templates-product-product-variable-tsx" */),
  "component---src-templates-sectors-archive-tsx": () => import("./../../../src/templates/SectorsArchive.tsx" /* webpackChunkName: "component---src-templates-sectors-archive-tsx" */),
  "component---src-templates-sectors-tsx": () => import("./../../../src/templates/sectors.tsx" /* webpackChunkName: "component---src-templates-sectors-tsx" */),
  "component---src-templates-shop-category-tsx": () => import("./../../../src/templates/shopCategory.tsx" /* webpackChunkName: "component---src-templates-shop-category-tsx" */),
  "component---src-templates-testimonials-archive-tsx": () => import("./../../../src/templates/testimonialsArchive.tsx" /* webpackChunkName: "component---src-templates-testimonials-archive-tsx" */),
  "component---src-templates-testimonials-single-tsx": () => import("./../../../src/templates/testimonialsSingle.tsx" /* webpackChunkName: "component---src-templates-testimonials-single-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/Footer/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/Header/Header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

